<template>
  <div>
    <v-btn
      color="primary"
      outlined
      small
      @click="addProperty"
      v-if="create"
    >
      {{'action.create'| t}}
    </v-btn>

    <div v-if="data">
      <v-simple-table
        fixed-header
      >
        <template #default>
          <thead>
            <tr>
              <th>#</th>
              <th class="text-left">
                {{'product.data.meta.key'| t}}
              </th>
              <th class="text-left">
                {{'product.data.meta.value'| t}}
              </th>
              <th class="px-0 mx-0" style="max-width: 120px"></th>
            </tr>
          </thead>

          <v-draggable
            tag="tbody"
            handle=".meta-item-draggable-handler"
            v-model="data"
          >
            <tr v-for="(metaItem, index) in data" :key="index">
              <td style="width: 20px">
                {{(index+1)}}
              </td>
              <td>
                {{metaItem.key}}
              </td>
              <td>
                <div v-html="nl2br(metaItem.value)"></div>
              </td>
              <td style="max-width: 120px">
                <v-btn
                  icon
                  color="orange"
                  class="meta-item-draggable-handler mr-2"
                >
                  <v-icon>fa fa-bars</v-icon>
                </v-btn>

                <v-btn
                  icon
                  @click="edit(index)"
                >
                  <v-icon>fa fa-edit</v-icon>
                </v-btn>

                <v-btn
                  icon
                  color="error"
                  @click="remove(index)"
                >
                  <v-icon>fa fa-trash</v-icon>
                </v-btn>
              </td>
            </tr>
          </v-draggable>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script lang="babel" type="text/babel">
import formItemMixin from '@/components/form/mixins/formItemMixin'
const MAX_LIMIT = 20
export default {
  mixins: [formItemMixin],
  mounted() {
    this.init()
  },
  methods: {
    init() {
      if(!Array.isArray(this.storeData)) {
        this.data = null
        return
      }

      this.data = window.eagleLodash.cloneDeep(this.storeData)
    },
    addProperty() {
      if(!Array.isArray(this.storeData)) {
        this.data = []
      }

      this.data.push({
        name: null,
        value: null,
      })
    },
    edit(index) {
      this.$apopup.base({
        title: this.$t('action.edit'),
        width: '450px',
        defaultValue: window.eagleLodash.cloneDeep(this.data[index]),
        bodySlot: () => import('./productMetaItemEditPopup.vue'),
        applyCallback: data => {
          console.warn('data', data)
          this.$set(this.data, index, data)
        },
      })
    },
    remove(index) {
      this.$apopup.base({
        title: this.$t('remove.confirm'),
        applyCallback: () => {
          this.$delete(this.data, index)
        },
      })
    },
  },
  computed: {
    metaItemQuantity() {
      if(!Array.isArray(this.data)) return 0
      return this.data.length
    },
    create() {
      return this.metaItemQuantity < MAX_LIMIT
    },
    tableHeight() {
      if(this.metaItemQuantity > 6) return 300
      return (this.metaItemQuantity+1)*60
    },
    productInstances() {
      if(!this.formData) return []
      return this.formData.instances
    },
  },
  watch: {
    data: {
      deep: true,
      handler() {
        if(window.eagleLodash.isEqual(this.data, this.storeData)) return
        this.sync()
      },
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>